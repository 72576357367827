<template>
  <div>
    <Header class="responsive-bar">
      <template v-slot:title>
        <span>
          {{ $t("profile-details") }}
        </span>
      </template>
    </Header>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(save)">
        <div
          v-if="get_ib_profile.address"
          class="bg-white shadow rounded-md max-w-5xl m-auto mt-5"
        >
          <div class="border-b border-gray-200">
            <div class="flex justify-between">
              <h3 class="text-lg leading-6 font-normal text-gray-900">
                <span>
                  {{ $t("personal-details") }}
                </span>
              </h3>
              <div></div>
            </div>
            <p class="mt-1 pb-4 text-sm leading-5 font-light text-gray-500">
              {{ $t("manage-how-information-is-displayed-on-your-account") }}
            </p>
          </div>
          <div>
            <dl>
              <div
                v-if="
                  !get_ib_profile.company ||
                  whitelabel === 'Blackstone' ||
                  whitelabel === 'TD365' ||
                  whitelabel === 'TDSouthAfrica' ||
                  whitelabel === 'OneTradingMarkets' ||
                  whitelabel === 'EagleGlobalMarkets' ||
                  whitelabel === 'TradeCoreUK'
                "
                class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b"
              >
                <dt class="text-sm leading-5 font-normal">
                  {{ $t("first-name-20db0bfeecd8fe60533206a2b5e9891a") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {{ get_ib_profile.first_name }}
                </dd>
              </div>
              <div
                v-if="
                  !get_ib_profile.company ||
                  whitelabel === 'Blackstone' ||
                  whitelabel === 'TD365' ||
                  whitelabel === 'TDSouthAfrica' ||
                  whitelabel === 'OneTradingMarkets' ||
                  whitelabel === 'EagleGlobalMarkets' ||
                  whitelabel === 'TradeCoreUK'
                "
                class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b"
              >
                <dt class="text-sm leading-5 font-normal">
                  {{ $t("last-name-8d3f5eff9c40ee315d452392bed5309b") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {{ get_ib_profile.last_name }}
                </dd>
              </div>
              <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm my-auto leading-5 font-normal sm:col-span-1">
                  {{ $t("Email") }}
                </dt>
                <dd
                  v-if="showUpdateEmail"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|email"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="email"
                        v-model="updatedUser.email"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{ get_ib_profile.email }}
                </dd>
                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateEmail"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateEmail = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateEmail"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateEmail = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>

              <div
                v-if="
                  whitelabel === 'TD365' ||
                  whitelabel === 'Blackstone' ||
                  whitelabel === 'TDSouthAfrica' ||
                  whitelabel === 'EagleGlobalMarkets' ||
                  whitelabel === 'TradeCoreUK'
                "
                class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b"
              >
                <dt class="text-sm my-auto leading-5 font-normal sm:col-span-1">
                  Skype name
                </dt>
                <dd
                  v-if="showUpdateSkype"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <div class="rounded-md shadow-sm">
                      <input
                        id="skypename"
                        v-model="updatedUser.address.skype"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{ get_ib_profile.address.skype }}
                </dd>

                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateSkype"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateSkype = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateSkype"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateSkype = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>

              <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm my-auto leading-5 font-normal">
                  {{ $t("Phone") }}
                </dt>
                <dd
                  v-if="showUpdatePhone"
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true, validatePhone: !phoneValid }"
                    name="telephone"
                  >
                    <div class="rounded-md shadow-sm">
                      <VuePhoneNumberInput
                        v-model="updatedUser.address.telephone"
                        :border-radius="6"
                        :disabled-fetching-country="true"
                        :no-use-browser-locale="true"
                        color="#a4cafe"
                        error-color="#E53E3E"
                        valid-color="#d2d6dc"
                        @update="phoneValidateCheck($event), (phone = $event)"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{ get_ib_profile.address.telephone }}
                </dd>
                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdatePhone"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdatePhone = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdatePhone"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdatePhone = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm leading-5 font-normal">
                  {{ $t("Country") }}
                </dt>
                <dd
                  v-if="get_profile.address"
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  {{ get_profile.address.addr_country }}
                </dd>
              </div>
              <h3
                class="text-lg leading-6 font-normal text-gray-900 py-5 border-gray-200"
              >
                {{ $t("Password") }}
              </h3>
              <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm leading-5 font-normal">
                  <button
                    class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="showChangePasswordModal = true"
                  >
                    {{ $t("change-password-0b39c5aca15b84b1ad53a94d6b3feb78") }}
                  </button>
                </dt>
              </div>
              <h3
                class="text-lg leading-6 font-normal text-gray-900 py-5 border-b border-gray-200"
              >
                {{ $t("Address") }}
                <p class="mt-1 pb-4 text-sm leading-5 font-light text-gray-500">
                  {{
                    $t("manage-how-information-is-displayed-on-your-account")
                  }}
                </p>
              </h3>
              <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm leading-5 font-normal">
                  {{ $t("Postcode") }}
                </dt>
                <dd
                  v-if="showUpdatePostcode"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true, thirtyDigits: true }"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="postcode"
                        v-model="updatedUser.address.addr_zip"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1 word-break"
                >
                  {{ get_ib_profile.address.addr_zip }}
                </dd>

                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdatePostcode"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdatePostcode = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdatePostcode"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdatePostcode = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm leading-5 font-normal">
                  {{ $t("City") }}
                </dt>
                <dd
                  v-if="showUpdateCity"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <div class="rounded-md shadow-sm">
                      <input
                        id="city"
                        v-model="updatedUser.address.addr_city"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1 word-break"
                >
                  {{ get_ib_profile.address.addr_city }}
                </dd>
                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateCity"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateCity = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateCity"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateCity = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm leading-5 font-normal">
                  {{ $t("address-line-1") }}
                </dt>
                <dd
                  v-if="showUpdateAddressLineI"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <div class="rounded-md shadow-sm">
                      <input
                        id="address_line_1"
                        v-model="updatedUser.address.addr_street"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1 word-break"
                >
                  {{ get_ib_profile.address.addr_street }}
                </dd>
                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateAddressLineI"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateAddressLineI = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateAddressLineI"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateAddressLineI = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>

              <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm leading-5 font-normal">
                  {{ $t("address-line-2") }}
                </dt>
                <dd
                  v-if="showUpdateAddressLineII"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <div class="rounded-md shadow-sm">
                      <input
                        id="address_line_2"
                        v-model="updatedUser.address.addr_line_2"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1 word-break"
                >
                  {{ get_ib_profile.address.addr_line_2 }}
                </dd>
                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateAddressLineII"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateAddressLineII = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateAddressLineII"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateAddressLineII = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div v-if="get_ib_profile.company">
                <h3
                  class="text-lg leading-6 font-normal text-gray-900 py-5 border-b border-gray-200"
                >
                  {{ $t("company-information") }}
                </h3>
                <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm leading-5 font-normal">
                    {{ $t("company-name") }}
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    {{ get_ib_profile.company.company_name }}
                  </dd>
                </div>
                <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm leading-5 font-normal">
                    {{ $t("company-website") }}
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    {{ get_ib_profile.company.website }}
                  </dd>
                </div>
                <template
                  v-if="
                    whitelabel === 'Blackstone' ||
                    whitelabel === 'TD365' ||
                    whitelabel === 'TDSouthAfrica' ||
                    whitelabel === 'OneTradingMarkets' ||
                    whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK'
                  "
                >
                  <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm leading-5 font-normal">
                      {{ $t("Country") }}
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ getCompanyCountry }}
                    </dd>
                  </div>
                  <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm leading-5 font-normal">
                      {{ $t("city-or-town") }}
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ get_ib_profile.company.addr_city }}
                    </dd>
                  </div>
                  <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm leading-5 font-normal">
                      {{ $t("address-line-1") }}
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ get_ib_profile.company.addr_line_1 }}
                    </dd>
                  </div>
                  <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm leading-5 font-normal">
                      {{ $t("address-line-2") }}
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ get_ib_profile.company.addr_line_2 }}
                    </dd>
                  </div>
                  <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm leading-5 font-normal">
                      {{ $t("tax-id-vat-id") }}
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ get_ib_profile.company.tax_id }}
                    </dd>
                  </div>
                </template>
              </div>
            </dl>
          </div>
          <change-password
            v-if="showChangePasswordModal"
            @close="close"
          ></change-password>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import ChangePassword from "@/views/documents/profile-details/ChangePassword";
import Header from "@/components/Header";

export default {
  name: "PersonalInformation",
  components: {
    VuePhoneNumberInput,
    ChangePassword,
    Header,
  },
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
      updatedUser: {},
      phone: "",
      showUpdateEmail: false,
      showUpdatePhone: false,
      showUpdateAddressLineII: false,
      showUpdateAddressLineI: false,
      showUpdateCity: false,
      showUpdatePostcode: false,
      showChangePasswordModal: false,
      showUpdateSkype: false,
      phoneValid: false,
      countries: [],
    };
  },
  computed: {
    ...mapGetters(["get_profile", "get_ib_profile", "get_countries"]),
    getCompanyCountry() {
      this.countries = this.get_countries;
      var countryObject = this.countries.find(
        (el) => el.value === this.get_ib_profile.company.country
      );
      return countryObject.name;
    },
  },
  mounted() {
    this.$store.dispatch("profile");
    this.$store.dispatch("ib_profile").then(this.updateFormData);
    this.$store.dispatch("countries");
  },

  methods: {
    updateFormData() {
      this.updatedUser = this.get_ib_profile;
    },
    save() {
      this.updatedUser.address.telephone = this.phone.formattedNumber;
      let data = {
        profile: this.updatedUser.profile,
        business_group: this.updatedUser.business_group,
        address: this.updatedUser.address,
        email: this.updatedUser.email,
      };
      if (this.get_ib_profile.company) {
        data.company = this.get_ib_profile.company;
      }
      this.$store
        .dispatch("update_ib_user_data", data)
        .then(() => {
          this.showUpdateEmail = false;
          this.showUpdatePhone = false;
          (this.showUpdateAddressLineII = false),
            (this.showUpdateAddressLineI = false),
            (this.showUpdateCity = false),
            (this.showUpdatePostcode = false),
            (this.showUpdateSkype = false),
            this.$store.dispatch("ib_profile").then(this.updateFormData);
          const t = this.$t.bind(this);
          this.$notify({
            group: "foo",
            text: `${t("your-personal-information-has-changed")}`,
          });
        })
        .catch((err) => {
          // error message is object > object > object > array
          // since we cannot target key value by name, we have to use object.keys[0] to get the first key/value
          // and we do that 2 times
          // but for nic_field error (ICMCapital) its one more layer of object before array
          // so we check if the first option is undefined, then we know its nic_field error so we target it by name
          // other error messages are for City, Address line 1, Address line 2 and Postcode
          let error = err.data[Object.keys(err.data)[0]];
          let errorMessage;
          if (typeof error[Object.keys(error)[0]] === "object") {
            if (error[Object.keys(error)[0]][0] === undefined) {
              if (
                err.data.nic_fields[0].non_field_errors[0] ===
                "Field length should be between 3 and 16"
              ) {
                errorMessage =
                  "National Passport Number should be between 3 and 16";
              }
            } else {
              errorMessage = error[Object.keys(error)[0]][0];
            }
          } else if (typeof error[Object.keys(error)[0]] === "string") {
            errorMessage = error[Object.keys(error)[0]];
          }
          // text: `${Object.values(err.data)}`,
          this.$notify({
            group: "foo",
            text: `${errorMessage}`,
            type: "warn",
          });
        });
    },
    close() {
      this.showChangePasswordModal = false;
    },
    cancel() {
      // we need to reset phone value to match v-model updatedUser.address.telephone value from input
      // because the phone value gets saved when user clicks cancel
      this.phone = this.updatedUser.address.telephone;
      this.$store.dispatch("ib_profile").then(this.updateFormData);
      this.showUpdateEmail = false;
      this.showUpdatePhone = false;
      this.showUpdateAddressLineII = false;
      this.showUpdateAddressLineI = false;
      this.showUpdateCity = false;
      this.showUpdatePostcode = false;
      this.showUpdateSkype = false;
    },
    // "vue-phone-number-input" emits event that has a isValid value
    // if isValid is false, we call a validatePhone validations taht will return false
    // and a error message
    phoneValidateCheck(phone) {
      this.phoneValid = phone.isValid;
    },
  },
};
</script>